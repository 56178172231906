import { useAppStore} from "@/store/App.store";
import {useHelpersStore} from "@/store/helpers";
import http from "axios";
import {cdiVars} from "@/cdiVars";
import { defineStore} from "pinia";
import _ from 'lodash';
export const useBusinessObjectStore = defineStore('businessObjectStore', {
    state: () => ({
        isInitialized:false,
        name: '',
        title: '',
        titlePlural: '',
        nav: [],
        currentView: '',
        object: {
            id: 0,
            fields: [],
            data: {},
            history: {},
        },
        searchFields: {
            formFields: {},
            endPoint: '',
            sortFields: false,
            resultFields: {},
            results: {},
        },
        history: {},
        customFileCallback: null,
        breadcrumbs: [],
        hideNav: false,
    }),

    actions: {
        initialize(activeObjectState) {
            let activeObjectStateClone = _.cloneDeep(activeObjectState);

            if (this.currentView.includes('override_')) {
                this.currentView = this.currentView.split('_')[1];
            } else {
                this.currentView = 'search';
            }
            if (activeObjectStateClone.search.resultFields) {
                this.searchFields.endPoint = activeObjectStateClone.search.endPoint || '';
                this.searchFields.sortFields = activeObjectStateClone.search.sortFields || false;
                this.searchFields.formFields = activeObjectStateClone.search.formFields || {};
                this.searchFields.resultFields = activeObjectStateClone.search.resultFields || {};
            }
            this.name = activeObjectStateClone.name;
            this.title = activeObjectStateClone.title;
            this.titlePlural = activeObjectStateClone.titlePlural;
            this.titleAlt = activeObjectStateClone.titleAlt;
            this.nav = activeObjectStateClone.nav;
            this.object.fields = activeObjectStateClone.object.fields;
            this.object.data = {};
            this.isInitialized = true;
            useAppStore().setTitle([
               activeObjectStateClone.titlePlural
            ]);
        },
        clearObjectData() {
            this.object.id = 0;
            this.object.data = {};
        },
        async post(url, qsPairs, resultField){
            const appStore = useAppStore();
            appStore.loadingMessage();
            let qsString = useHelpersStore().getQSString(qsPairs);

                const res = await http.post(url,qsString, {headers: useHelpersStore().authHeader()});
                this.searchFields.results = res.data;
                return this.handleResponse(resultField, res.data);

        },

        /**
         * if resultField is a string, this.state.string is updated with the data
         * if resultField is undefined, the data is returned
         */
        handleResponse(resultField, data) {
            const appStore = useAppStore();
            switch (data.opt) {
                case 'denied':
                    /*       appStore.autoSignOut();*/
                    break;
                case 'error':
                    appStore.errorBox(data.msg +'.....');
                    this.object.data.opt = 'error';
                    if (typeof resultField === 'undefined' || resultField === false) {
                        return 'error';
                    }
                    break;
                case 'warning':
                    appStore.warningBox(data.msg);
                    break;
                default:
                    appStore.successBox?.(data.msg);
                    if (!resultField) {
                        return data;
                    } else {
                        try {
                            return useHelpersStore().nestedSet(this, resultField, data);
                        } catch (error) {
                            return 'error';
                        }
                    }
            }
        },

        api(endPoint, options, resultField, restFile = this.name) {
            return this.post(`${cdiVars.API_URL}/${restFile}/${endPoint}/${this.object.id}`, options, resultField);
        },

        customApi(endPoint, options, resultField) {
            return new Promise(resolve =>
                resolve(this.post(`${cdiVars.API_URL}/${endPoint}`, options, resultField))
            );
        },

        apiWithObjectLoad(endPoint, options) {
            return this.api(endPoint, options, 'object.data');
        },
        apiGeneric(url, options, resultField) {
            return this.post(`${cdiVars.API_URL}/${url}`, options, resultField);
        },
        search(endPoint, options, resultField) {
            return this.post(`${cdiVars.API_REPORT_URL}/${endPoint}/to_json`, useHelpersStore().getQSPairs(options), resultField);
        },
        async searchMorePost(url, qsPairs, resultField){
            const appStore = useAppStore();
            appStore.loadingMessage();
            let qsString = useHelpersStore().getQSString(qsPairs);
            const res = await http.post(url,qsString, {headers: useHelpersStore().authHeader()});
            return this.handleResponse(resultField, res.data);
        },

        // runs a search but concats the new rows to the previous ones instead of replacing
        async searchMore(endPoint, options) {
            this.searchMorePost(`${cdiVars.API_REPORT_URL}/${endPoint}/to_json`, useHelpersStore().getQSPairs(options), false)
            .then(
                results => {
                    if (results && results.rows) {
                        const currentRows = this.searchFields.results.rows || [];
                        results.rows = currentRows.concat(results.rows);
                        useHelpersStore().nestedSet(this, 'searchFields.results', results);
                    }
                })
                .catch()
        },
        load(id, view = 'details') {
            this.breadcrumbs = [];
            this.object.id = id;
            this.currentView = view;
            return this.apiWithObjectLoad('to_json', {});
        },
        save(field, val) {
            field = `params[${field}]`;
            return this.apiWithObjectLoad('save', { [field]: val });
        },
        getHistory() {
            return this.api('history_json', {}, 'history');
        }
    }
});