<template>
    <footer class="footer text-center text-uppercase">
        <div v-if="!isHidden" class="alert alert-warning dev-notice">
            <p>Please send a detailed message about the bug you are <br />experiencing and the dev team will work to resolve it as soon as possible.</p>
            <textarea v-model="formField" name="" id="" class="feedback_reply form-control" cols="40"
                      rows="10">
            </textarea>
            <br/>
            <button class="btn btn-default" @click="submitForm">Submit</button>
            &nbsp;
            <button class="btn btn-default" @click="closeMenu">Close</button>
        </div>
        <ul class="list-unstyled list-inline footer_list">
            <li><a href="/locations">Locations</a></li>
            <li><a href="/employment">Employment</a></li>
            <li>888-234-7001</li>
            <li>Proudly Made in America</li>
            <li><a href="https://cdicurbs.atlassian.net/wiki/external/MjQzYjI1ZTAzOTJjNDc5Mzk2NGE1ZjZjN2FlZGZjYjI">API</a></li>
            <li @click="toggleMenu"><a>Report Bug</a></li>
        </ul>
    </footer>
</template>

<script>
import http from "axios";
import {cdiVars} from "@/cdiVars";
import {useHelpersStore} from "@/store/helpers";
import {useAppStore} from "@/store/App.store";

export default {
    name: "FooterComponent",
    data() {
        return {
            isHidden: true,
            formField: '',
        }
    },

    methods: {
        toggleMenu: function () {
            if (this.isHidden) {
                this.isHidden = false;
            }
        },
        closeMenu: function () {
            if (!this.isHidden) {
                this.isHidden = true;
            }
        },
        submitForm: function () {
            if (!this.formField == '') {
                http.post(`${cdiVars.API_URL}/site_request/save_feedback/0`, useHelpersStore().getQSString({'params[detail]': this.formField + "\n\n" + this.$route.fullPath}), {headers: useHelpersStore().authHeader()})
                    .then(() => {
                            this.isHidden = true;
                            this.formField = '';
                            useAppStore().successBox('Feedback sent.');
                        }
                    );
            }
        }
    },
    computed: {
        isStaff: function () {
            return useAppStore().isStaff();
        }
    }
}
</script>

<style scoped>

</style>