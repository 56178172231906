import { defineStore } from 'pinia';
import qs from "qs";

// Helper function to remove circular references
function removeCircularReferences(obj) {
    const seenObjects = new WeakSet();

    function detectCycle(value) {
        if (value !== null && typeof value === 'object') {
            if (seenObjects.has(value)) {
                return '[Circular]'; // Replace circular references with a marker
            }
            seenObjects.add(value);
        }
        return value;
    }

    return JSON.parse(JSON.stringify(obj, (key, value) => detectCycle(value)));
}

export const useHelpersStore = defineStore('helpersStore', {
    actions: {
        getQSPairs(data, paramsPrepend = 'params') {
            let qsPairs = {};

            for (const field in data) {
                let value = data[field]['val'];

                if (qsPairs[`${paramsPrepend}${data[field]['qsField']}`]) {
                    if (qsPairs[`${paramsPrepend}${data[field]['qsField']}`].length) {
                        if (data[field]['qsField']) {
                            qsPairs[`${paramsPrepend}${data[field]['qsField']}`].push(value);
                        }
                    } else {
                        qsPairs[`${paramsPrepend}${data[field]['qsField']}`] = [
                            qsPairs[`params${data[field]['qsField']}`],
                            value
                        ];
                    }
                } else {
                    qsPairs[`${paramsPrepend}${data[field]['qsField']}`] = value;
                }
            }
            return qsPairs;
        },

        getQSString(qsPairs) {
            try {
                qsPairs = removeCircularReferences(qsPairs);
                return qs.stringify(qsPairs);
            } catch (error) {
                return null;
            }
        },

        authHeader() {
            const token = localStorage.getItem('token');
            return token ? { Authorization: `Bearer ${token}` } : {};
        },

        canAccess(accessRules, state) {
            let userRoles = Object.keys(state.user.roles).map(Number);

            if (!accessRules) return true;

            if (userRoles.includes(1)) return true;

            if (accessRules.roles && userRoles.some(r => accessRules.roles.includes(r))) return true;

            if (accessRules.isStaff && state.user.userTypeID === 4) return true;

            if (accessRules.loggedIn && state.loggedIn) return true;

            return false;
        },

        nestedSet(obj, path, value) {
            let schema = obj;
            const pList = path.split('.');
            const len = pList.length;

            for (let i = 0; i < len - 1; i++) {
                const elem = pList[i];
                if (!schema[elem]) {
                    schema[elem] = {};
                }
                schema = schema[elem];
            }

            schema[pList[len - 1]] = value;
        },

        getUserBrowser() {
            const userAgent = navigator.userAgent;
            if (userAgent.includes("Firefox")) return "Mozilla Firefox";
            if (userAgent.includes("SamsungBrowser")) return "Samsung Internet";
            if (userAgent.includes("Opera") || userAgent.includes("OPR")) return "Opera";
            if (userAgent.includes("Trident")) return "Microsoft Internet Explorer";
            if (userAgent.includes("Edge")) return "Microsoft Edge";
            if (userAgent.includes("Chrome")) return "Google Chrome or Chromium";
            if (userAgent.includes("Safari")) return "Apple Safari";

            return "unknown";
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
});