<template>
    <div id="alert_box" class="alert alert-warning" v-html="state.appMessage"></div>
</template>

<script>
import {useAppStore} from "@/store/App.store";

export default {
        name: "MessageBox",
        data() {
            return {
                state: useAppStore().$state,
            }
        }
    }
</script>

<style scoped>

</style>