import {useHelpersStore} from "@/store/helpers";

export default {
     mounted(el) {
         const helperStore = useHelpersStore();
        if (['Mozilla Firefox', 'Google Chrome or Chromium'].includes(helperStore.getUserBrowser())) {
            el.addEventListener('mousedown', event => {
                if(event.target.className !== "form-control") {
                    el.setAttribute('draggable', true);
                    el.resetXPosition = getComputedStyle(el).left;
                    el.resetYPosition = getComputedStyle(el).top;

                        el.addEventListener('dragstart', function(event) {
                            // Instantiate positioning variables
                            el.positioning = {
                                originalPosition: {
                                    x: getComputedStyle(el).left.replace('px', ''),
                                    y: getComputedStyle(el).top.replace('px', '')
                                },
                                cursorPosition: {
                                    departure: {
                                        x: 0,
                                        y: 0
                                    },
                                    destination : {
                                        x: 0,
                                        y: 0
                                    },
                                    cursorDiff: {
                                        x: 0,
                                        y: 0
                                    }
                                },
                            }
                            // Set departure coordinates
                            el.positioning.cursorPosition.departure = { x: event.clientX, y: event.clientY };
                        });

                    el.addEventListener('drag', function(event) {
                        event.preventDefault();
                    });

                    el.addEventListener('dragend', function(event) {
                        event.preventDefault();
                        // Set cursor's end point
                        let cursor = el.positioning.cursorPosition;
                        cursor.destination = {
                            x: event.clientX,
                            y: event.clientY
                        };

                        // Get the difference between the cursor's start and end points
                        cursor.cursorDiff = {
                            x: cursor.destination.x - cursor.departure.x,
                            y: cursor.destination.y - cursor.departure.y
                        };

                        // Set the window's new location
                        el.style.left = `${parseInt(el.positioning.originalPosition.x) + parseInt(cursor.cursorDiff.x)}px`;
                        el.style.top = `${parseInt(el.positioning.originalPosition.y) + parseInt(cursor.cursorDiff.y)}px`;

                        el.setAttribute('draggable', false);
                        el.resetXPosition = getComputedStyle(el).left;
                        el.resetYPosition = getComputedStyle(el).top;

                    })
                }
            });



        }


    },
    unmounted(el) {
        el.style.left = el.resetXPosition;
        el.style.top = el.resetYPosition;
    }
};