import { defineStore } from 'pinia'
import http from "axios";
import {useHelpersStore} from "./helpers";
import { cdiVars } from '@/cdiVars';

export const useAppStore
    = defineStore('appStore', {
    state: () => ({
        user: {
            defaultLocationID: null,
            roles: {},
            userID: null,
            userName: null,
            companyName: null,
            userTypeID: null,
            autoDrawingUserTeam: null,
            fileAccessToken: null,
            schedulerLocation: null,
            termsDate: null,
            dashboard : null
        },
        loggedIn: false,
        termsDate: '',
        appMessage: '',
        errorText: '',
        warningText: '',
        toast: {
            text: '',
            link: '',
        },
        fileUploadLocation: '',
        fileUploadContext: null,
        fileUploadModalOpen: false,
        fileUploadContextData: {
            referenceID: 0,
            reference2ID: 0,
            conformingTypeID: 0,
            data: {},
            metadata: {}
        },
        massFileUploadContext: null,
        fileUploadCallback: null,
        fileMassUploadCallback: null,
        alerts: [],
        recentQuotes: [],
        recentOrders: [],
        showPublicNav: true,

        /** Track view on a global level for file upload */
        fileCallbackView: null,
        synchronizeFileCallbackView: false

    }),
    actions: {
        toggleShowNav() {
            this.showPublicNav = !this.showPublicNav;
        },
        async signIn(formFields) {
            let qsString = useHelpersStore().getQSString(useHelpersStore().getQSPairs(formFields));
            try{
                const response = await http.post(`${cdiVars.API_URL}/mycdi/jwt_signin`, qsString);
                if(response.data){
                    let tokenString = JSON.stringify(response.data);
                    let tokenJSON = JSON.parse(tokenString);
                    localStorage.setItem('token', tokenJSON.token)
                    await this.loadToken();
                }else{
                   this.signOut();
                }
            } catch(error){
                this.errorBox('Sign in failed! Check your user name and password or reset your password.')
            }
        },
signOut(redirect = '/') {
           http.post(`${cdiVars.API_URL}/myuser/delete_file_access_token/${this.user.userID}`, '', {headers: useHelpersStore().authHeader()})
                .then(() => {
                        this.user = {roles: {}};
                        this.loggedIn = false;
                        this.termsDate = '';
                        localStorage.removeItem('token');
                        window.location.replace(redirect);
                    }
                );
        },
        autoSignOut() {
            this.signOut();
            this.errorBox('Your Session has expired. Please log in again.')
        },
        loadToken() {
            let token = localStorage.getItem('token');
            token = (token !== 'undefined') ? token : null;
            if (token) {
                let payload = JSON.parse(atob(token.split('.')[1]));
                this.user = payload.data;
               /* if (this.user.companyName === 'no company' || this.user.userTypeID === 5) {
                    this.signOut('/new_user_r');
                }*/
                this.loggedIn = true;
                this.termsDate = payload.data.termsDate;
            }
        },
        loadingMessage() {
            this.appMessage = '<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Loading...';
        },
        successBox(msg) {
            this.appMessage = msg;
            if (msg) {
                setTimeout(() => this.appMessage = '', 1500);
            }
        },
        errorBox(msg) {
            this.appMessage = '';
            this.errorText = msg;
        },
        warningBox(msg) {
            this.appMessage = '';
            this.warningText = msg;
        },
        toast(txt, link = '') {
            this.toast.text = txt;
            this.toast.link = link;
        },
        setFileUploadContext(context) {
            this.fileUploadContext = context;
        },
        setFileMassUploadContext(context) {
            this.massFileUploadContext = context;
        },
        setFileUploadContextData(data) {
            let referenceID = parseInt(data.referenceID);
            let reference2ID = parseInt(data.reference2ID);
            let conformingTypeID = parseInt(data.conformingTypeID);
            delete data.referenceID;
            delete data.reference2ID;
            delete data.conformingTypeID;


            this.fileUploadContextData = {
                referenceID: referenceID ? referenceID : 0,
                reference2ID: reference2ID ? reference2ID : 0,
                conformingTypeID: conformingTypeID ? conformingTypeID : 0,
                ...data
            };
        },
        setFileUploadCallback(fn) {
            this.setFileUploadCallback = fn
        },
        setFileMassUploadCallback(fn) {
            this.fileMassUploadCallback = fn;
        },
        hasRole(allowedRoles) {
            let userRoles = Object.values(this.user.roles)

            if (userRoles.includes(cdiVars.CDI_ROLE_SUPER)) {
                return true;
            }

            if (Array.isArray(allowedRoles)) {
                for (let i = 0; i < allowedRoles.length; i++) {
                    if (userRoles.includes(allowedRoles[i])) {
                        return true;
                    }
                }
                return false
            } else {
                return userRoles.includes(allowedRoles);
            }
        },
        isStaff() {
            return this.user.userTypeID === cdiVars.USER_TYPE_EMPLOYEE;
        },
        isStaffExcludingShop() {
            return this.hasRole([
                cdiVars.CDI_ROLE_HR,
                cdiVars.CDI_ROLE_SALES,
                cdiVars.CDI_ROLE_ACCOUNTING,
                cdiVars.CDI_ROLE_ENG,
                cdiVars.CDI_ROLE_QC,
                cdiVars.CDI_ROLE_SHOP_SUP,
                cdiVars.CDI_ROLE_LOGISTICS,
                cdiVars.CDI_ROLE_MATERIAL
            ]);
        },
        isContractor() {
            return this.user.userTypeID === cdiVars.USER_TYPE_CONTRACTOR;
        },
        validateEmail(email) {
            var regex = /\S+@\S+\.\S+/;
            return regex.test(email);
        },
        setTitle(titleAr = []) {
            if (!Array.isArray(titleAr)) {
                return;
            }
            titleAr.unshift('CDI Curbs');
            document.title = titleAr.join(' - ');
        },
        setUserFileAccessToken(token) {
            this.user.fileAccessToken = token;
        },
        getUserFileAccessToken() {
            return this.user.fileAccessToken;
        },
        getAlerts() {
            http.post(`${cdiVars.API_URL}/mycdi/unread_alerts`, '', {headers: useHelpersStore().authHeader()})
                .then(response => this.alerts = response.data.alerts);
        },
        refreshFileAccessToken() {
            http.post(`${cdiVars.API_URL}/mycdi/regenerate_file_access_token`, {}, {headers: useHelpersStore().authHeader()}).then(result => {
                this.setUserFileAccessToken(result.data.token);
            });
        },
        readAlert(alertID) {
            http.post(`${cdiVars.API_URL}/myuser/mark_alert_read`, `params=${alertID}`, {headers: useHelpersStore().authHeader()})
                .then(() => this.getAlerts());
        },
        recentQuotesOrders() {
            let quoteParams = {
                "params[page_size]": "2",
                "params[direction]": 'DESC',
                "params[orderby]": 'quote_date',
                "params[show_my_ccs]": 'on',
            };
            http.post(`${cdiVars.API_REPORT_URL}/quotes/to_json`, useHelpersStore().getQSString(quoteParams), {headers: useHelpersStore().authHeader()})
                .then(response => this.recentQuotes = (this.response?.data?.rows) ? response.data.rows.map(quote => quote.quote_id) : []);

            let trackerParams = {
                "params[page_size]": "2",
                "params[direction]": 'DESC',
                "params[orderby]": 'tracker_date',
                "params[show_my_ccs]": 'on',
            };
            http.post(`${cdiVars.API_REPORT_URL}/trackers/to_json`, useHelpersStore().getQSString(trackerParams), {headers: useHelpersStore().authHeader()})
                .then(response => this.recentOrders = (this.response?.data?.rows) ? response.data.rows.map(tracker => tracker.tracker_id) : []);
        },
        validateFileAccess() {
            http.post(`${cdiVars.API_URL}/mycdi/has_valid_file_access_token`, {
                'params[token]': this.getUserFileAccessToken()
            }, {
                headers: useHelpersStore().authHeader()
            }).then(result => {
                if (!result.valid) {
                    http.post(`${cdiVars.API_URL}/mycdi/regenerate_file_access_token`, {}, {headers: useHelpersStore().authHeader()}).then(result => {
                        this.setUserFileAccessToken(result.data.token);
                    });
                }
            });
        }
    },
});