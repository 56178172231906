<template>
  <ModalComponent id="upload" heading="Mass File Upload" @close="close">
    <template v-slot:body>
      <div v-if="state.file !== null">
        <keep-alive>
          <component v-bind:is="currentComponent" style="overflow-y: scroll!important; max-height: 425px; margin-bottom: 5px"></component>
        </keep-alive>
      </div>

      <!-- TODO maybe a watch method or something to auto-trigger the upload instead of showing this to the user -->
      <div class="form-group" v-show="!state.file && state.progress === 0">
        <label class="btn btn-default">
          Choose file
          <input type="file" ref="file" name="files[]" @change="uploadFile()" />
        </label>
      </div>
      <div v-show="state.file && appState.fileUploadContext !== 'cdi_file'">
                <span v-show="state.allowAttach">
                    <button class="btn btn-primary btn-block" type="button" @click='holdFile()' >
                        Upload file
                    </button>
                </span>
        <button class="btn btn-default btn-block" type="button" @click='startOver()'>Start over</button>
      </div>
      <br>
      <small>All file uploads are subject to our <a href="/privacy_policy" target="_blank">Privacy Policy</a></small>
    </template>
  </ModalComponent>
</template>

<script>
import { useMassFileUploadStore } from "@/store/MassFileUpload.store";
import ModalComponent from "@/components/utils/ModalComponent.vue";
import {useBusinessObjectStore} from "@/store/BusinessObject.store";
import {useAppStore} from "@/store/App.store";
import {defineAsyncComponent} from "vue";
export default {
  name: "MassFileUpload",
  components: {
    ModalComponent,
    conforming_mass_upload:  defineAsyncComponent(() => import('@/components/utils/fileHandlers/ConformingMassUpload')),
  },
  created() {
    useAppStore().setFileMassUploadCallback(function () {
      useBusinessObjectStore().load('', 'mass_upload');
    });
  },
  data() {
    return {
      state: useMassFileUploadStore().$state,
      appState: useAppStore().$state,
    }
  },
  methods: {
    uploadFile: function () {
      useMassFileUploadStore().setFile(this.$refs.file.files[0]);
      useMassFileUploadStore().setTitle(this.$refs.file.files[0].name);
    },
    holdFile: function() {
      useMassFileUploadStore().$state.massUploadFilePresent = true;
      this.close();
    },
    startOver: function () {
      this.$refs.file.value = [];
      useMassFileUploadStore().resetData(false);
      useAppStore().setFileMassUploadContext('conforming_mass_upload');
    },
    close: function () {
      useAppStore().$state.fileUploadModalOpen = false;
      this.$refs.file.value = [];
    }
  },
  computed: {
    currentComponent: function() {
      if (!this.appState.massFileUploadContext) {
        return null;
      }
      let current = `${this.appState.massFileUploadContext}`;
      useAppStore().setFileMassUploadContext(current);
      return current;
    },
    conformingTypeID: function() {
      return this.state.conformingTypeID;
    },
    fileUploadContext: function() {
      return this.appState.massFileUploadContext;
    },
    fileUploadModalOpen: function () {
      return this.appState.fileUploadModalOpen;
    }
  },
  watch: {}
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>