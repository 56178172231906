<template>
    <header>
        <div class="header_container">
            <router-link to="/" class="header_logo_circle mobile_hide">
            <span class="mobile_hide header_logo_desktop">
                <img src="../../assets/cdi-logo-wheel-color.png">
                &nbsp; <b>CDI</b> custom curb adapters</span>
            </router-link>
                <span class=" header_search input-group input_group_omni_search ui-front">
                        <label class="input-group-addon"><span class="glyphicon glyphicon-search"></span></label>
                        <TypeaheadComponent cdi_type="omni_search"
                                   ref="omniSearch"
                                   :minLength="3"
                                   :inputAttributes="{class: 'input-lg search_input'}"
                                   placeholder="Search"
                                   @objectSelected="omniSelect"
                                   class="dropbtn"
                        />
                </span>
            <span class="phone_number">888-234-7001 &nbsp;</span>
            <div class="nav_links_mobile">
                <NavLinks />
            </div>
        </div>
    </header>
</template>

<script>
import {useBusinessObjectStore} from "@/store/BusinessObject.store";
import TypeaheadComponent from "@/components/utils/typeaheads/TypeaheadComponent.vue";
import NavLinks from "@/components/layout/NavLinks.vue";
import {useAppStore} from "@/store/App.store";

export default {
    name: "HeaderComponent",
    components:{
        TypeaheadComponent,
        NavLinks
    },
    data() {
        return {
            appState: useAppStore().$state,
            showLogIn: false,
            response: {},
            formFields: {
                email: { val: '', qsField: '[email]' },
                password: { val: '', qsField: '[passwd]' },
            },
          state: useBusinessObjectStore().$state
        }
    },
    methods: {
        setView: function(view) {
            this.state.currentView = view;
        },
        omniSelect: function (object) {
            this.$router.push(object.value);
            this.$refs.omniSearch.$data.object = '';
        },
    }
}
</script>

<style scoped>
.header_list{
    width: 50px;
}
.header_container{
    display: block;
    min-height: 90px;
    margin-left: 5%;
    margin-right: 5%;
    }
@media (max-width: 850px) {
    .create-account {
        width: 100%;
        min-width: 100px!important;
        font-size: 11px!important;
    }
    .forgot-pass{
        float: left!important;
    }
    .mobile_hide {
        display: none !important;
    }
}
</style>
