<template>
    <nav class="header_nav_container">
        <div class="header_center">
            <div v-click-outside="handleOutsideClick" class="list-unstyled list-inline nav_list">
                <div class="header_sign_in">
                    <div v-if="!appState.loggedIn" class="dropdown">
                        <div>
                            <router-link :to="'/my'" class="btn header_create_quote" tabindex="4">
                                        <span>
                                            SIGN IN
                                        </span>
                            </router-link>

                            <div class="btn header_create_quote">
                                <router-link v-show="appState.user.userTypeID !== 5" class="btn_user" :to="`/quote/new`"  @click="createAQuote()" >
                                    CREATE A QUOTE</router-link>
                            </div>
                        </div>
                    </div>
                    <HeaderMyCDI v-else />
                    <div class="tiny_logo_name">
                        <a id="home-icon" href="/">
                            <img src="../../assets/cdi-logo-wheel-color-mobile.png" class="name" id="mobile-wheel">
                            <span class="name" id="name"> <b> CDI curbs</b></span>
                        </a>
                    </div>
                </div>
                <div><NavLinks class="nav_links" /></div>
            </div>
        </div>
    </nav>
</template>
<script>
import NavLinks from './NavLinks';
import clickOutside from "@/directives/clickOutside";
import {useAppStore} from "@/store/App.store";
import HeaderMyCDI from "@/components/layout/HeaderMyCDI.vue";
import {useBusinessObjectStore} from "@/store/BusinessObject.store";
export default {
    name: "NavComponent",
    components: {
        NavLinks,
        HeaderMyCDI
    },
    data() {
        return {
            appState: useAppStore().$state,

        }
    },
    methods:{
        createAQuote: function (){
            useBusinessObjectStore().currentView = 'override_new'
        },
        toggleLogIn: function() {
            this.showLogIn = !this.showLogIn;
        },
        signIn: function() {
            useAppStore().signIn(this.formFields);
            this.showLogIn = false;
            this.formFields.email.val = '';
            this.formFields.password.val = '';
        },
        handleOutsideClick: function () {
            this.showLogIn = false;
            this.showMyCDI = false;
        },
    },
    directives: {
        clickOutside: clickOutside
    }
}
</script>

<style scoped>
.header_nav_container{
    background-color: #343538;
}
.header_create_quote {
    color: #fff !important;
}
</style>