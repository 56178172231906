<template>
    <span>
        <input type="text"  v-click-outside="handleOutsideClick" v-bind:placeholder="placeholder" class="form-control"
               v-bind="inputAttributes"
               v-model="object"
               :disabled="disabled"
               @keyup.esc="close"
               @input="debouncedSearch"
               @keydown.down="moveFocusDown"
               @keydown.up="moveFocusUp"
               @keydown.enter="enterSelect"
               @focus="focusSearch"
        >
        <ul class="ui-autocomplete typeahead" v-show="suggestions" style="max-height: 300px; overflow: auto">
            <li class="ui-menu-item"
                v-for="(suggestion, index) in suggestions"
                v-bind:key="index"
                :class="{ 'is_active': index === focusIndex }"
                :data-id="suggestion.value"
                :data-object="suggestion.label"
                @click="clickSelect(index)">
             {{ suggestion.label }}
            </li>
        </ul>
        <div v-if="isModalOpen" class="modal">
    <div class="modal-content">
        <div class="modal-header">
            <h2>All Results</h2>
            <button class="close-button" @click="isModalOpen = false">&#10005;</button>
        </div>
        <ul class="ui-autocomplete typeahead">
            <li v-for="(result, index) in allResults" :key="index"
                :class="{ 'is_active': index === focusIndex }"
                :data-id="result.value" :data-object="result.label"
                @click="clickSelectModal(index)">
                {{ result.label }}
            </li>
        </ul>
    </div>
</div>

    </span>

</template>

<script>
import {useHelpersStore} from "@/store/helpers";
import clickOutside from "@/directives/clickOutside";

export default {
    name: "TypeaheadComponent",
    data() {
        return {
            object: '',
            suggestions: '',
            focusIndex: -1,
            timer: null,
            isModalOpen: false,
            selectedObject: null,
            allResults: []
        }
    },
    props: {
        placeholder: {},
        cdi_type: {},
        inputAttributes: {type: Object},
        autoPopulate: {
            default: false,
        },
        clearOnSelect: {
            default: false
        },
        minLength: {
            type: Number,
            default: 1
        },
        params: {},
        displayValue: {
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        parseObject: {
            default: false
        },
        acceptPlainText: {
            default: false
        }
    },
    methods: {
        openModalWithAllResults: function () {
            this.isModalOpen = true;
            let local_this = this;
            this.$http.get(`${ this.$cdiVars.API_URL }/typeahead/${ this.cdi_type }?term=${ this.object }&all=true`, {headers: useHelpersStore().authHeader()}).then(function (res) {
                if (res.data.length > 0) {
                    local_this.allResults = res.data;
                }
            }).catch();
        },
        clickSelectModal: function (index) {
            let selected = this.allResults[index];
            this.select(selected.value, selected);
            this.isModalOpen = false;
        },
        debouncedSearch: function () {
            this.emitValue();
            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                this.search();
            }, 1000);
        },
        search: function () {
            if (this.object.length > 0) {
                if (this.object.length < this.minLength) {
                    return;
                }
                let local_this = this;
                let params = '';
                if (this.params) {
                    params = '&' + useHelpersStore().getQSString(this.params);
                }
                this.$http.get(`${ this.$cdiVars.API_URL }/typeahead/${ this.cdi_type }?term=${ this.object }${params}`, {headers: useHelpersStore().authHeader()}).then(function (res) {
                    if (res.data.length > 0) {
                        local_this.suggestions = res.data
                    } else {
                        local_this.close();
                    }
                }).catch();
            } else {
                // Reset stored data if nothing is typed
                this.select(0, '');
                this.close();
            }
        },
        autoSearch: function () {
            let local_this = this;
            this.$http.get(`${ this.$cdiVars.API_URL }/typeahead/${ this.cdi_type }`, {headers: useHelpersStore().authHeader()}).then(function (res) {
                if (res.data.length > 0) {
                    local_this.suggestions = res.data
                } else {
                    local_this.close();
                }
            }).catch();
        },
        clickSelect: function (index) {
            let selected = this.suggestions[index];
            if (selected.value === 0) {
                this.openModalWithAllResults();
                this.close();
            } else {
                this.select(selected.value, selected);
            }
        },
        enterSelect: function (e) {
            e.preventDefault();
            let selected = this.suggestions[this.focusIndex];
            if (selected) {
                this.select(selected.value, selected);
            }
        },
        select: function (id, object) {
            if (this.parseObject) {
                id = object.id;
            }
            this.selectedObject = object;
            this.object = object.label;
            this.$emit('update:ID', id);
            this.$emit('update:modelValue', object.value);
            this.$emit('update:object', object);
            this.$emit('objectSelected', object);

            this.close();
        },
        moveFocusDown: function () {
            this.focusIndex++;
        },
        moveFocusUp: function () {
            this.focusIndex--;
        },
        close: function () {
            if (this.clearOnSelect) {
                this.object = '';
            }
            this.suggestions = '';
            this.focusIndex = -1;
        },
        handleOutsideClick: function () {
            if (this.isModalOpen && this.selectedObject) {
                this.object = this.selectedObject.label;
            }
            this.close();
        },
        emitValue: function() {
            // Allows plain text for additions if the typeahead does not have a suitable option
            if (this.acceptPlainText) {
                this.$emit('valueChange', {value: this.object});
            }
        },
        focusSearch: function() {
            if (this.autoPopulate) {
                this.autoSearch();
            }
        }
    },

    watch: {
        displayValue: {
            immediate: true,
            handler() {
                this.object = (this.displayValue) ? this.displayValue : '';
            }
        }
    },
    directives: {
        clickOutside: clickOutside
    }
}
</script>
<style scoped>
.modal {

}
.ui-autocomplete{
    height: 800%;
    overflow-y: scroll;
}
.modal-content {
    position: relative;
}

.modal-header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    background-color: #e5e8ed;
    border: 1px solid #d4d8dd;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
}

.close-button {
    border: none;
    background: transparent;
    font-size: 15px;
    cursor: pointer;
    color: #333;
    padding: 0;
    margin: 0;
    padding-left: 174px
;
}

/* Ensuring the h2 has minimal margin to align properly */
h2 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}
@media screen and (max-width: 800px){
    .modal{
        margin-left: 50%!important;
    }

}
</style>