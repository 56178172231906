import {useHelpersStore} from "@/store/helpers";
import { cdiVars } from "@/cdiVars";
import http from "axios";
import { defineStore} from "pinia";
import { useAppStore} from "@/store/App.store";

export const useFileUploadStore = defineStore('fileUploadStore', {
	state: () => ({
			baseUrl: process.env.VUE_APP_URL,
			file: null,
			progress: 0,
			fileID: null,
			fileTypeID: cdiVars.FILE_TYPE_UNASSIGNED_FL,
			referenceID: null,
			reference2ID: null,
			conformingTypeID: null,
			fileTitle: null,
			fileName: null,
			allowAttach: true,
			errors: [],
			warnings: [],
			isModal: false,
			trackerID: 0,
			quoteID:0
		}),
		actions: {
			uploadFile() {
				const helpersStore = useHelpersStore();
				const appStore = useAppStore();

				let formData = new FormData();
				formData.append('files[]', this.file);

				http.post(`${this.baseUrl}/api/cdi_file/upload`, formData, {
					headers: helpersStore.authHeader(),
					onUploadProgress: (progressEvent) => {
						this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					}
				})
					.then((response) => {
						if (response.data.opt === 'error') {
							appStore.errorBox(response.data.msg);
							return;
						}
						this.fileName = response.data.file_name;
						this.fileID = response.data.cdi_file_id;
					})
					.catch((error) => {
						appStore.errorBox('Failed to upload file' + error);
					});
			},
			attachFile() {
				const appStore = useAppStore();
				let fn = appStore.$state.fileUploadCallback;

				let queryString = useHelpersStore().getQSString(this.options());
				http.post(`${this.baseUrl}/api/cdi_file/attach_file`, queryString, {headers: useHelpersStore().authHeader()}).then(result => {
					switch (result.data.opt) {
						case 'error':
							appStore.errorBox(result.data.msg);
							break;
						case 'warning':
							appStore.warningBox(result.data.msg);
							break;
						default:
							appStore.successBox('File Attached');
							break;
					}

					if (typeof fn === 'function') {
						fn();

					}
					appStore.$state.synchronizeFileCallbackView = true;

					this.resetData();
				});
			},
			setFile(file) {
				this.file = file;
			},
			setTitle(title) {
				this.fileTitle = title;
			},
			initialize(fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL) {
				const appStore = useAppStore();
				this.fileTypeID = fileTypeID;
				this.referenceID = appStore.$state.fileUploadContextData.referenceID;
				this.reference2ID = appStore.$state.fileUploadContextData.reference2ID;
				this.conformingTypeID = appStore.$state.fileUploadContextData.conformingTypeID;
			},
			blankReferenceID() {
				this.referenceID = '';
			},
			resetData(keepFileData = false) {
				const appStore = useAppStore();
				if (!keepFileData) {
					this.file = null;
					this.progress = 0;
					this.fileID = null;
					this.fileTitle = null;
					this.fileName = null;
				}

				this.allowAttach = true;

				this.fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL;
				this.referenceID = 0;
				this.reference2ID = 0;
				this.conformingTypeID = 0;

				appStore.setFileUploadContext(null);
				appStore.setFileUploadContextData({
					referenceID: 0,
					reference2ID: 0,
					data: {},
					metadata: {},
					conformingTypeID: 0,
				});
				appStore.setFileUploadCallback(() => {
				});
			},
			options() {
				const appStore = useAppStore();
				let options = {
					'params[handler]': appStore.$state.fileUploadContext,
					'params[cdi_reference_id]': this.referenceID,
					'params[cdi_reference2_id]': this.reference2ID,
					'params[cdi_file_type_id]': this.fileTypeID,
					'params[cdi_file_title]': this.fileName,
					'params[cdi_file_id]': this.fileID,
					'params[conforming_type_id]': this.conformingTypeID,
				};

				if (this.trackerID) {
					options['params[tracker_id]'] = this.trackerID;
				}

				if (this.quoteID) {
					options['params[quote_id]'] = this.quoteID;
				}

				return options;
			},
			loadMetadata() {
				const appStore = useAppStore();
				let queryString = useHelpersStore().getQSString(this.options());
				http.post(`${this.baseUrl}/api/cdi_file/get_metadata`, queryString, {headers: useHelpersStore().authHeader()}).then(result => {
					appStore.setFileUploadContextData({
						referenceID: this.referenceID,
						reference2ID: this.reference2ID,
						metadata: result.data
					});
				});
			}
		}
	})