<template>
    <div class="dropdown" v-click-outside="handleOutsideClick">
        <div class="header_create_quote">
            <router-link
                v-show="appState.user.userTypeID !== 5"
                class="btn_user"
                :to="`/quote/new`"
                @click.native="setView('override_new')"
            >
                CREATE A QUOTE</router-link>
        </div>
        <button class="btn_user signed_in_user" @click="toggleMyCDI">
            MY CDI
            <span v-show="numAlerts > 0"> : {{numAlerts}}</span>
        </button>

        <div class="dropdown-menu dropdown-menu-right login_panel" :class="[showMyCDI ? 'cdi_show' : 'cdi_hide']">
            <div class="nav_box_header">{{appState.user.userName}}</div>
            <div v-show="appState.user.userTypeID !== 5">
                <ul class="simple_list nav_box_list">
                    <li><router-link to="/my"><span @click="showMyCDI = false">My CDI</span></router-link></li>

                    <li v-if="isStaff"><a href="http://mail.cdicurbs.com" target="_blank">Email</a></li>
                    <li v-if="isStaff"><a href="https://drive.google.com" target="_blank">Drive</a></li>
                    <li v-if="isStaff"><router-link to="/clock"><span @click="showMyCDI = false">Time Clock</span></router-link></li>

                    <li v-if="isStaff">
                        <router-link :to="`/quote?userID=${appState.user.userID}`"><span @click="showMyCDI = false">Quotes</span></router-link>:
                        <router-link :to="`/quote/${appState.recentQuotes[0]}`"><span @click="showMyCDI = false">{{appState.recentQuotes[0]}}</span></router-link>
                        <router-link :to="`/quote/${appState.recentQuotes[1]}`" style="padding-left: 5px;"><span @click="showMyCDI = false">{{appState.recentQuotes[1]}}</span></router-link>
                    </li>
                    <li v-if="!isStaff">
                        <router-link to="/myorder"><span @click="redirectFromHeader">Orders</span></router-link>:
                        <router-link :to="`/myorder/${appState.recentOrders[0]}`"><span @click="showMyCDI = false">{{appState.recentOrders[0]}}</span></router-link>
                        <router-link :to="`/myorder/${appState.recentOrders[1]}`" style="padding-left: 5px;"><span @click="showMyCDI = false">{{appState.recentOrders[1]}}</span></router-link>
                    </li>
                </ul>
                <div v-if="isStaff" class="header_alert_title" @click="showAlerts = !showAlerts">Alerts <span class="pull-right badge">{{numAlerts}}</span></div>
                <div v-show="alertCover" class="header_alert_cover">
                    <router-link :to="activeAlert.url" @click.native="readAlert(activeAlert.cdi_alert_id)" class="btn btn-default header_alert_button">View &amp; Mark as Read</router-link>
                    <router-link :to="activeAlert.url" @click.native="clearAlert" class="btn btn-default header_alert_button">View</router-link>
                    <button class="btn btn-default header_alert_button" @click="readAlert(activeAlert.cdi_alert_id, false)">Mark as Read</button>
                    <button class="btn btn-default header_alert_button" @click="alertCover = false">Cancel</button>
                </div>
                <ul v-if="numAlerts && showAlerts" class="header_alert_container">
                    <li v-for="alert in appState.alerts" :key="alert.cdi_alert_id" title="alert.cdi_alert_dt">
                        <a class="header_alert" @click="showAlertOptions(alert)">{{alert.cdi_alert}}</a>
                    </li>
                </ul>
            </div>
            <button @click="signOut" class="btn btn-default btn-sm btn_sign_out pull-right">Sign Out</button>
        </div>
    </div>
</template>

<script>
import clickOutside from "@/directives/clickOutside";
import { useBusinessObjectStore } from '@/store/BusinessObject.store.js';
import {useAppStore} from "@/store/App.store";

export default {
    name: "HeaderMyCDI",
    data() {
        return {
            $appStore : useAppStore(),
            appState: useAppStore().$state,
            showMyCDI: false,
            activeAlert: {
                cdi_alert_id: 0,
                url: '',
            },
            showAlerts: false,
            alertCover: false,
            state: useBusinessObjectStore().$state,

        }
    },
    methods: {
        setView: function(view) {
            this.state.currentView = view;
        },
        reloadPage(path) {
            window.location.href = path;
        },
        handleOutsideClick: function () {
            this.showMyCDI = false;
            this.clearAlert();
        },
        signOut: function() {
            useAppStore().signOut();
            this.showMyCDI = false;
            this.showLogIn = false;
        },
        toggleMyCDI: function() {
            this.showMyCDI = !this.showMyCDI;
        },
        showAlertOptions: function(alert) {
            this.alertCover = true;
            this.activeAlert = alert;
        },
        readAlert: function(alertID, close = true) {
            useAppStore().readAlert(alertID);
            if (close) {
                this.clearAlert();
            } else {
                this.alertCover = false;
                this.activeAlert = {
                    cdi_alert_id: 0,
                    url: '',
                };
            }
        },
        redirectFromHeader: function(){
            this.showMyCDI = false;
            this.state.currentView = 'search'
        },
        clearAlert: function() {
            this.showMyCDI = false;
            this.showAlerts = false;
            this.alertCover = false;
            this.activeAlert = {
                cdi_alert_id: 0,
                url: '',
            };
        },
    },
    computed: {
        numAlerts: function() {
            return (this.appState.alerts ? this.appState.alerts.length : 0);
        },
        isStaff: function () {
            return useAppStore().isStaff();
        },
    },
    created: function () {
        if (useAppStore().isStaff()) {
            useAppStore().getAlerts();
            window.setInterval(() => {
                if (document.visibilityState === 'visible') {
                    useAppStore().getAlerts()
                }
            }, (60000 * 10)); // check for new alerts every 10 minutes
        } else {
            useAppStore().recentQuotesOrders();
        }
    },
    mounted() {
        if (this.appState.loggedIn) {
            useAppStore().refreshFileAccessToken();
            // Fire a file token refresh every 45 minutes (15 minute short of expiration)
            window.setInterval(() => {useAppStore().refreshFileAccessToken()}, (60000 * 45));
        }
    },
    directives: {
        clickOutside: clickOutside
    }
}
</script>

<style scoped>

.dropdown-menu {
    min-width: 210px !important;
}

@media (max-width: 850px) {
    .btn_sign_out {
        width: 100%;
        min-width: 100px!important;
    }
}
.badge {
    color: #333333;
}

</style>