<template>
    <div id="app" class="container container_site">
        <div>
            <div class="navbar-fixed-top wrapper">
                <div class="header"><HeaderComponent/></div>
                <div class="nav"><NavComponent/></div>
            </div>
            <br/>
            <div class="router_view">
                <router-view/>
            </div>


            <FooterComponent class="footer"/>
            <MessageBox v-show="appStore.$state.appMessage"/>
            <ErrorBox v-show="appStore.$state.errorText"/>
            <WarningBox v-show="appStore.$state.warningText"/>
            <MassFileUpload v-show="appStore.$state.fileUploadModalOpen"/>
            <FileUpload ref="fileUploader" v-show="appStore.$state.fileUploadContext"/>
            <QrLink v-show="appStore.hasRole([cdiVars.CDI_ROLE_SHOP])" />

            <div v-show="!isProduction()" class="alert alert-danger dev-notice">
                <p>This is a <strong>development</strong> server</p>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderComponent from './components/layout/HeaderComponent.vue';
import NavComponent from './components/layout/NavComponent.vue';
import FooterComponent from './components/layout/FooterComponent.vue';
import MessageBox from "./components/utils/MessageBox";
import ErrorBox from "./components/utils/ErrorBox";
import WarningBox from "@/components/utils/WarningBox";
import FileUpload from "@/components/utils/fileHandlers/FileUpload";
import MassFileUpload from "@/components/utils/fileHandlers/MassFileUpload";
import { watch, computed, defineComponent} from "vue";
import { useAppStore} from "@/store/App.store";
import { useRoute } from 'vue-router';
import QrLink from "@/components/utils/QrLink.vue";
import {cdiVars} from "@/cdiVars";

export default defineComponent({
    name: 'app',
    components: {
        HeaderComponent,
        NavComponent,
        FooterComponent,
        MessageBox,
        QrLink,
        MassFileUpload,
        FileUpload,
        WarningBox,
        ErrorBox,
    },
    data() {
        return {
            appState: useAppStore().$state
        }
    },

    setup() {
        const appStore = useAppStore();

        const token = localStorage.getItem('token');
        if (token) {
            appStore.$state.loggedIn = true;
            appStore.loadToken();
        }

        const route = useRoute();
       /* const router = useRouter();*/
        const loggedIn = computed(() => useAppStore().$state.loggedIn);
        const appMessage = appStore.$state.appMessage;
        const errorText = appStore.$state.errorText;
        const warningText = computed(() => appStore.$state.warningText);
        const fileUploadModalOpen = computed(() => appStore.$state.fileUploadModalOpen);
        const fileUploadContext = computed(() => appStore.$state.fileUploadContext);
        const isProduction = () => process.env.VUE_APP_URL === 'https://www.cdicurbs.com';

        // watch(loggedIn, (newLoggedInState, oldLoggedInState) => {
        //    if ((newLoggedInState === true && oldLoggedInState === false && route.meta.redirectToMy === true) || newLoggedInState === false && oldLoggedInState === true) {
        //         router.push({path: '/my'});
        //     } else {
        //         router.go();
        //     }
        // })

        watch(() => route, (to) => {
            to.meta.fullWidth === true ? document.body.classList.add('full_width') : document.body.classList.remove('full_width');
        });

        return {
            loggedIn,
            appMessage,
            errorText,
            warningText,
            fileUploadModalOpen,
            fileUploadContext,
            isProduction,
            appStore,
            cdiVars
        };
    },
})
</script>

<style>
@import 'assets/css/bootstrap.min.css';
@import 'assets/css/cdi.css';

#app {

}

.dev-notice {
    position: fixed;
    margin: 0;
    bottom: 10px;
    right: 0;
    text-align: center !important;
    z-index: 10000;
}

.router_view {
    margin-top: 10px;
    margin-right: 0px !important;
    margin-bottom: 60px !important;
}

@media screen and (max-width: 850px) {
    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .wrapper div:first-child {
        order: 1;
    }

    .nav {
        z-index: 1000 !important;
        text-decoration: none !important;
        padding-bottom: 5px;
    }

    .header {
        top: 50px;
        position: absolute;
        z-index: 2 !important;
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0px !important;
    }
}

</style>