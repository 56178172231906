<template>
    <ModalComponent id="upload" heading="File Upload" @close="close">
        <template v-slot:body>
            <div v-if="state.file !== null && state.fileID">
                <keep-alive>
                    <component v-bind:is="currentComponent"
                               style="overflow-y: scroll!important; max-height: 425px; margin-bottom: 5px">
                    </component>
                </keep-alive>
            </div>

            <!-- TODO maybe a watch method or something to auto-trigger the upload instead of showing this to the user -->
            <div class="form-group" v-show="!state.fileID && state.progress === 0">
                <label class="btn btn-default">
                    Choose file
                    <input type="file" ref="file" name="files[]" @change="uploadFile()" />
                </label>
            </div>
            <div v-show="state.fileID && appState.fileUploadContext !== 'cdi_file'">
                <span v-show="state.allowAttach">
                    <button class="btn btn-primary btn-block" type="button" @click='attachFile()' :disabled="!state.referenceID || isDisabled" >
                        Upload file
                    </button>
                </span>
                <button class="btn btn-default btn-block" type="button" @click='startOver()'>Start over</button>
            </div>
            <br>
            <small>All file uploads are subject to our <a href="/privacy_policy" target="_blank">Privacy Policy</a></small>
        </template>
    </ModalComponent>
</template>

<script>
    import { useFileUploadStore } from "@/store/FileUpload.store";
    import ModalComponent from "@/components/utils/ModalComponent.vue";
    import {useBusinessObjectStore} from "@/store/BusinessObject.store";
    import {useAppStore} from "@/store/App.store";
    import {defineAsyncComponent} from "vue";

    export default {
        name: "FileUpload",
        components: {
            ModalComponent,
            asset: defineAsyncComponent( () => import('@/components/utils/fileHandlers/AssetComponent.vue')),
            asset_maintenance_schedule: defineAsyncComponent( () => import('@/components/utils/fileHandlers/AssetMaintenanceDocument')),
            batch_utilization_data: defineAsyncComponent( () => import('@/components/utils/fileHandlers/BatchUtilizationData')),
            cdi_file: defineAsyncComponent( () => import('@/components/utils/fileHandlers/CDIFile')),
            company: defineAsyncComponent( () => import('@/components/utils/fileHandlers/CompanyComponent.vue')),
            conforming_stamp: defineAsyncComponent( () => import('@/components/utils/fileHandlers/ConformingComponent.vue')),
            curb: defineAsyncComponent (() => import('@/components/utils/fileHandlers/CurbComponent.vue')),
            curb_conforming: defineAsyncComponent (() => import('@/components/utils/fileHandlers/CurbConforming')),
            curb_training_documents: defineAsyncComponent (() => import('@/components/utils/fileHandlers/CurbTrainingDocument')),
            curb_variant: defineAsyncComponent (() => import('@/components/utils/fileHandlers/CurbVariant')),
            eco: defineAsyncComponent (() => import('@/components/utils/fileHandlers/ECO')),
            material: defineAsyncComponent (() => import('@/components/utils/fileHandlers/MaterialComponent.vue')),
            item_batch: defineAsyncComponent (() => import('@/components/utils/fileHandlers/ItemBatch')),
            model: defineAsyncComponent (() => import('@/components/utils/fileHandlers/ModelImport')),
            part: defineAsyncComponent (() => import('@/components/utils/fileHandlers/PartComponent.vue')),
            po: defineAsyncComponent (() => import('@/components/utils/fileHandlers/PO')),
            quote: defineAsyncComponent( () => import('@/components/utils/fileHandlers/QuoteComponent.vue')),
             quote_curb: defineAsyncComponent (() => import('@/components/utils/fileHandlers/QuoteCurb')),
            quote_notebook: defineAsyncComponent (() => import('@/components/utils/fileHandlers/QuoteNotebook.vue')),
            tracker_mer: defineAsyncComponent (() => import('@/components/utils/fileHandlers/TrackerMER')),
            user: defineAsyncComponent (() => import('@/components/utils/fileHandlers/UserComponent.vue')),
            tracker: defineAsyncComponent (() => import('@/components/utils/fileHandlers/TrackerComponent.vue')),
            tracker_curb: defineAsyncComponent (() => import('@/components/utils/fileHandlers/TrackerCurb')),
            // Bad naming but needs to be this way because of app-reliance
            notebook: defineAsyncComponent (() => import('@/components/utils/fileHandlers/TrackerNotebook')),
            cogs: defineAsyncComponent (() => import('@/components/utils/fileHandlers/CogsComponent.vue')),
            maintenance_log: defineAsyncComponent (() => import('@/components/utils/fileHandlers/MaintenanceLog')),
            maintenance_photo: defineAsyncComponent (() => import('@/components/utils/fileHandlers/MaintenancePhoto'))
        },
        created() {
            useAppStore().setFileUploadCallback(function () {
                if(useBusinessObjectStore().$state.currentView === 'details'){
                    useBusinessObjectStore().load(useBusinessObjectStore().$state.object.id);
                }
            });
        },
        data() {
            return {
                state: useFileUploadStore().$state,
                appState: useAppStore().$state,
                isDisabled: false,
            }
        },
        methods: {
            uploadFile: function () {
                useFileUploadStore().setFile(this.$refs.file.files[0]);
                useFileUploadStore().setTitle(this.$refs.file.files[0].name);
                useFileUploadStore().uploadFile();
            },
            attachFile: function () {
                useFileUploadStore().attachFile();
                this.close();
                this.$refs.file.value = '';
            },
            startOver: function () {
                useFileUploadStore().resetData(true);
                useAppStore().setFileUploadContext('cdi_file');
            },
            close: function () {
                useFileUploadStore().resetData();
                this.$refs.file.value = null;
            }
        },
        computed: {
            currentComponent: function() {
                if (!this.appState.fileUploadContext) {
                    return null;
                }
                let current = `${useAppStore().$state.fileUploadContext}`;
                useAppStore().setFileUploadContext(current);
                return current;
            },
          conformingTypeID: function() {
              return this.state.conformingTypeID;
          },
          fileUploadContext: function() {
              return this.appState.fileUploadContext
          }
        },
      watch: {
          currentComponent: function () {
              if (useAppStore().$state.fileUploadContext === "curb_conforming") {
                  this.isDisabled = true;
              }
          },
          conformingTypeID: function () {
              if (this.state.conformingTypeID > 0) {
                  this.isDisabled = false;
              }
          },
          fileUploadContext: function () {
              if (this.appState.fileUploadContext === "maintenance_log") {
                  let cdiLocation = this.appState.fileUploadLocation;
                  useAppStore().setFileUploadCallback(function () {
                      useBusinessObjectStore().apiWithObjectLoad('to_json', {
                          'params[cdi_location_id]': cdiLocation,
                      })
                  });
              }
          }
      }
    }
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
</style>