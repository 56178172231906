import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import {cdiVars} from './cdiVars';
import { useAppStore} from './store/App.store'
import './registerServiceWorker'
import { createPinia } from 'pinia';
import router from "@/router";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'globalthis/auto';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

/*appStore.loadToken();*/
const app = createApp(App);

app.component('VueDatePicker', VueDatePicker);

const pinia = createPinia();
app.use(createPinia());
app.config.globalProperties.$http = axios;
app.config.globalProperties.$cdiVars = cdiVars;
app.config.globalProperties.$appStore = useAppStore();
app.config.globalProperties.$isEmpty = function (value) {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        parseInt(value) === 0
    );
};

app.config.globalProperties.$currency = (value, currency = 'USD', decimalPlaces = 2, round = 'round') => {
    value = parseFloat(value).toFixed(decimalPlaces);
    if (round === 'round') {
        value = Math.round(value);
    } else if (round === 'ceil') {
        value = Math.ceil(value);
    } else if (round === 'floor') {
        value = Math.floor(value);
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
    }).format(value);
};


app.use(router);
app.use(pinia)
app.mount('#app');