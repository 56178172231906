<template>
    <transition name="modal_fade">
        <div class="modal_backdrop">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">

                <div class="modal_header" id="modalTitle">
                    CDI Warning
                    <button type="button" class="modal_btn_close" @click="close" aria-label="Close modal">
                        <span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body" id="modalDescription">{{state.warningText}}</div>
            </div>
        </div>
    </transition>
</template>

<script>
import {useAppStore} from "@/store/App.store";

export default {
        name: "WarningBox",
        data() {
            return {
                state: useAppStore().$state,
            }
        },
        methods: {
            close: function () {
                useAppStore().warningBox('');
            }
        }
    }
</script>

<style scoped>
    .modal_header {
        color: #8a6d3b;
        font-weight: bold;
        background-color: #fcf8e3;
    }
</style>