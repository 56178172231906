import {useHelpersStore} from "@/store/helpers";
import { cdiVars } from "@/cdiVars";
import http from "axios";
import {useAppStore} from "@/store/App.store";
import {defineStore} from "pinia";

export const useMassFileUploadStore = defineStore('massFileUploadStore', {
    state: () => ({
        baseUrl: process.env.VUE_APP_URL,
        file: null,
        progress: 0,
        fileID: null,
        fileTypeID: cdiVars.FILE_TYPE_UNASSIGNED_FL,
        referenceID: null,
        reference2ID: null,
        conformingTypeID: null,
        fileTitle: null,
        fileName: null,
        allowAttach: true,
        errors: [],
        warnings: [],
        isModal: false,
        massUploadFilePresent: false
    }),
    actions: {
    massUpload: function (curbsToUpdate) {
        const appStore = useAppStore();
        let formData = new FormData();
        formData.append('files[]', this.file);
        formData.append('curbs_to_update', curbsToUpdate);
        formData.append('cdi_file_type_id', this.fileTypeID);
        formData.append('conforming_type_id', this.conformingTypeID);
        let fn = appStore.$state.fileMassUploadCallback;

        http.post(`${ this.baseUrl }/api/cdi_file/mass_upload`, formData,
            {headers: useHelpersStore().authHeader()}).then(result => {
            switch (result.data.opt) {
                case 'error':
                    appStore.errorBox(result.data.msg);
                    break;
                case 'warning':
                    appStore.warningBox(result.data.msg);
                    break;
                default:
                    appStore.successBox('File Attached');
                    break;
            }

            if (typeof fn === 'function') {
                fn();
            }
            appStore.$state.synchronizeFileCallbackView = true;

            this.resetData();
        });
    },
    setFile: function (file) {
        this.file = file;
    },
    setTitle: function (title) {
        this.fileTitle = title;
    },
    initialize: function (fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL) {
        const appStore = useAppStore();
        this.fileTypeID = fileTypeID;
        this.referenceID = appStore.$state.fileUploadContextData.referenceID;
        this.reference2ID = appStore.$state.fileUploadContextData.reference2ID;
        this.conformingTypeID = appStore.$state.fileUploadContextData.conformingTypeID;
    },
    resetData: function (keepFileData = false) {
        const appStore = useAppStore();
        if (!keepFileData) {
            this.file = null;
            this.progress = 0;
            this.fileID = null;
            this.fileTitle = null;
            this.fileName = null;
        }

        this.allowAttach = true;

        this.fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL;
        this.referenceID = 0;
        this.reference2ID = 0;
        this.conformingTypeID = 0;

        appStore.setFileMassUploadContext(null);
        appStore.setFileUploadContextData({
            referenceID: 0,
            reference2ID: 0,
            data: {},
            metadata: {},
            conformingTypeID: 0,
        });
        appStore.setFileUploadCallback(() => {
        });
    },
    loadMetadata: function () {
        const appStore = useAppStore();
        let queryString = useHelpersStore().getQSString(this.options());
        http.post(`${ this.baseUrl }/api/cdi_file/get_metadata`, queryString, {headers: useHelpersStore().authHeader()}).then(result => {
            appStore.setFileUploadContextData({
                referenceID: this.referenceID,
                reference2ID: this.reference2ID,
                metadata: result.data
            });
        });
    }
}
})